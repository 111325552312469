:root{
  --green:#4e9c4b;
  --yellow: #ffb800;

  --text-color: #333;
  --background-color: #f9f9f9;
  --container-padding: 20px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex-i{
  display: flex;
  align-items: center;
}
.flex-s{
  display: flex;
  align-items: flex-start;
}
.flex-a{
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-initial{
  text-align: left !important;
}

.custom-list {
  /* padding-left: 20px;  */
  list-style-type: disc !important; 
}

.custom-list li {
  list-style-position: outside; 
  margin-left: 30px; 
  text-align: justify; 
}
/* styles for credit rebuilder page */
.green{
  color: #4e9c4b;
}
.yellow{
  color: var(--yellow);
}
.container__small{
  max-width: 1000px !important;
}

.space_for_website_top{
  padding-top: 150px;
}
/* .dropdown {
  position: relative;
  display: inline-block;
  width: 220px;
  padding-right: 5px;
}
.dropdown-button {
  background-color: #66bb6a !important; 
  color: #ffffff !important;
  border: 1px solid #4caf50 !important;
  background-color: #f0f0f0 !important;
  padding: 4px 10px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ccc !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-content {
  background-color: #e8f5e9 !important;
  color: #2e7d32 !important;
  height:80vh;
  overflow:auto;
  display: none;
  position: absolute;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-item {
  padding: 8px 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
  background-color: #4caf50;
  color: #ffffff;
} */
.dropdown-item{
  text-wrap: auto;
  white-space: unset !important;
}
.crbldrSec1__heading{
  text-align: center;
  font-size: 43px;
  font-weight: bold;
  margin-bottom: 20px;
}
.crbldrSec1__para{
  font-size: 22px;
  color: #333333;
  margin-bottom: 40px;
  max-width: 800px;
  margin-inline: auto;
  text-align: center;
}
.crbldrSec1__heading2{
  color: #222;
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
}

.crbldrSec1__col1{
  width: 60%;
}
.crbldrSec1__col2{
  width: 40%;
}
.crbldrSec1__listBox__heading{
  background-color: var(--green);
  font-size: 20px;
  color: #fff;
  padding: 20px 10px;
  font-weight: 600;
  margin-bottom: 0;
}
.crbldrSec1__listBoxList{
  text-align: left;
  background-color: rgba(40,41,54,1);
  color: #e7e7e7;
  padding: 35px 22px 80px 26px ;
  font-size: 17px;
  margin: 0;
  position: relative;
}
.crbldrSec1__listBoxList__span{
  margin-bottom: 20px;
  display: block;
}
.crbldrSec1__listBoxList__span--2{
  margin-top: 20px;
}
.crbldrSec1__listBoxList__item{
  position: relative;
  padding-left: 15px;
}
.crbldrSec1__listBoxList__item::before{
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #e7e7e7;
  position: absolute;
  top: 8px;
  left: 0;
}
.rbldrSec1__listBoxList__linkBtn{
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  top: auto;
  background-color: var(--yellow);
  width: 80%;
  color: #000;
  /* border-radius: 5px; */
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 10px;
}
.rbldrSec1__listBoxList__linkBtn:hover{
  background-color: #f2a500;
}
.crbldrSec1__col1__desc{
  font-size: 17px;
  color: #777;
  margin-bottom: 20px;
}
.couterNumber{
  width: 38px;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
}
.couterNumberBox{
  width: 90%;
}
.couterNumberBox__para{
  width: calc(100% - 60px);
  font-size: 21px;
  color: #222;
  font-weight: 400;
}
.couterNumberBox{
  justify-content: space-between;
}
.couterNumberBoxHolder{
  transform: translateY(-200px);
}
.questionCallBox{
  border-radius: 100px;
  width: 80%;
  background: rgba(199, 220, 128, 1);
  border: 1px solid  #ccc;
  margin-top: 46px;
  text-align: center;
  padding: 10px 20px;
  margin-inline: auto;
  margin-bottom: 23px;

}
.questionCallBox__heading{
  font-size: 20px;
  color: #222;
  font-weight: 600;
}
.questionCallBox__btn{
  background-color: var(--green);
  width: 90%;
  margin-inline: auto;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  padding: 5px;
}
.pricingbox{
  text-align: center;
  color: #222222;
}
.pricingbox__heading{
  font-size: 22px;
  font-weight: 700;
}
.pricingbox__subHeading{
  font-size: 16px;
  font-weight: 700;
}
.pricingbox__subHeading2{
  font-size: 14px; 
  font-weight: 700;
}

.credit-comparison {
  display: flex;
  justify-content: center;
  gap: 45px;
  transform: translateY(-100px);
}

.credit-comparison__column {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 20px;
  position: relative;
  padding-bottom: 110px;
}

.credit-comparison__header {
  padding: 16px 19px 32px 20px;
  color: white;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 0 0;
}

.credit-comparison__header--green {
  background-color: #4CAF50;
}

.credit-comparison__header--yellow {
  background-color: #FFC107;
}

.credit-comparison__title {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  max-width: 250px;
}

.credit-comparison__content {
}

.credit-comparison__text {
  margin: 0;
  color: #0c1922;
  border-bottom: 1px dashed #26323859;
  padding: 20px;
  font-size: 14px;
}

.credit-comparison__text--link {
  color: #2196F3;
  text-decoration: underline;
}
.credit-comparison__text--link:hover{
  color: #2196F3;
}
.credit-comparison__cross-icon {
  width: 21px;
  height: 21px;
  display: block;
}

.credit-comparison__link {
  color: #2196F3;
  text-decoration: none;
  display: block;
  margin: 1rem 0;
}

.credit-comparison__link:hover {
  text-decoration: underline;
}

.credit-comparison__button {
  width: 70%;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s;
  position: absolute;
  padding: 12px 15px;
  min-height: 64px;
  font-size: 16px;
  line-height: 1.2;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  text-align: center;
}

.credit-comparison__button:hover {
  opacity: 0.9;
}

.credit-comparison__button--green {
  background-color: var(--green);
}

.credit-comparison__button--yellow {
  background-color: var(--yellow);
}
.secDevider{
  padding: 30px 0;
  background-color: var(--green);
}
.secDevider__heading{
  font-size: 34px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.featuresSec{
  padding: 70px 0 65px 0;
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 20px;
}

.feature {
}

.feature__icon {
  margin-bottom: 20px;
  border-radius: 10px;
}
.feature__iconimg{
  border-radius: 10px;
}
.feature__title {
  font-size: 22px;
  margin-bottom: 10px;
  color: var(--green);
  font-weight: bold;
}

.feature__description {
  font-size: 14px;
  color: #777;
  margin-bottom: 0;
}
.cta {
  margin-top: 60px;
  text-align: center;
}

.cta__button {
  background-color: var(--yellow);
  width: 100%;
  max-width: 293px;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
}
.cta__button:hover{
  background-color: #f2a500;
}


/* CommentSection.css */
.comment-section{
  padding: 50px 0;
}
.commentCard_wrapper {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  gap: 20px 2%;
  flex-wrap: wrap;
  
}

.comment-card {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 24px;
  width: 32%;
}

.comment-image {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 15px;
}

.comment-name {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

.comment-location {
  color: #666;
  margin-bottom: 0;
  font-size: 12px;
}

.comment-text {
  line-height: 1.4;
  font-size: 15px;
  color: #777;
}
.comment-card--half{
  width: 49%;
}

.faq {
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.faq__item{
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 15px 0;
  margin-bottom: 0;
}
.faq__icon{
  font-size: 26px;
  font-weight: bold;
  transition: transform 0.3s ease;
}
.faq__question {
  cursor: pointer;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
  color: #222;
  font-weight: bold;
}
.faq__answer {
  font-size: 16px;
  color: #333333;
  line-height: 1.6;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  margin-bottom: 10px;
}

.faq__item--active .faq__answer {
  max-height: 500px;
  opacity: 1;
  padding: 0px 15px;
}
.faq__question:hover,
.faq__item--active .faq__question {
  color: var(--yellow);
}
.faqSec{
  padding: 45px 0;
}
.faq__item:nth-last-child(1){
  border-bottom: none;
}
.faq__answer:nth-last-child(1){
  margin-bottom: 0;
}
.about7steps{
  padding: 45px 0 0 0 ;
}
.about7steps__text{
  text-align: center;
  display: block;
  width: 100%;
  max-width: 800px;
  margin-inline: auto;
  line-height: 1.5;
  font-size: 21px;
  color: rgb(51, 51, 51);
}
.about7steps__text__link{
  text-decoration: underline;
}
.about7steps__text__link:hover{
  color: rgb(51, 51, 51);
}
.cta__button--about7steps{
  width: 100%;
  max-width: 480px;
  font-size: 15px;
}
.cta--about7steps{
  margin-top: 30px;
}

/* Add more styles as needed */
/* styles end for credit rebuilder page */


/* financialeducation page styling start */

.container__fnedu{
  width: 100% !important;
  max-width: 1110px !important;
}
.container__fnedu2{
  width: 100% !important;
  max-width: 1200px !important;
}
.fnedu_heroSection{
  padding: 0 0 135px 0;
  background-color: #157727;
  position: relative;
  z-index: 5;
}
.fnedu_heroSection__top{
  padding-top:85px ;
  background: url("../src//assets//images//financialeducation/financialeducation__heroSection_top_bg.jpg");
  background-position: 50%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.fnedu_heroSection::after{
  content: "";
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 0;
  background: url("../src//assets//images//financialeducation/financialeducation__heroSection_bottom_bg.svg");
  width: 100%;
  height: 100px;
  transform: scale(-1, -1);
}
.fnedu_heroSection__position{
  position: relative;
  z-index: 10;
}
.fnedu_heroSection__logo{
  width: 170px;
  height: auto;
  margin-bottom: 30px;
}
.fnedu_heroSection__heading{
  font-weight: 800;
  font-size: 63px;
  color: #19352d;
  line-height: 1.2;
}
.fnedu_heroSection__subheading{
  color: #333;
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 600;
}
.signup-form {
  background: white;
  padding: 35px 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  margin-left: 50px;
  border: 5px solid #4e9c4b;
}

.signup-form__title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.signup-form__description {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 80px;
}

.signup-form__form {
  display: flex;
  flex-direction: column;
}

.signup-form__label {
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.signup-form__input {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.signup-form__checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.signup-form__checkbox {
  margin-top: 4px;
}

.signup-form__checkbox-label {
  font-size: 14px;
}

.signup-form__link {
  color: #2196F3;
  text-decoration: none;
  font-weight: 500;
}
.signup-form__link:hover{
  color: #2196F3;
}
.signup-form__button {
  background: #2196F3;
  color: white;
  padding: 13px;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
  border: 2px solid #2196F3;
}

.signup-form__button:hover {
  background: transparent !important;
  color: #2196F3;
}


.fnedu_heroSectionLisy__text{
  font-size: 18px;
  color: #333;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
li.fnedu_heroSectionLisy__text{
  padding-left: 20px;
  position: relative;
}
li.fnedu_heroSectionLisy__text::before{
  content: "";
  width: 7px;
  height: 7px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;

}
.top_row{
  margin-bottom: 130px;
}

.financial-literacy {
  padding: 40px 20px;
}

.financial-literacy__title {
  font-size:32px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 60px;
}

.financial-literacy__highlight {
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.financial-literacy__highlight::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background-color: #d4d084;
}

.financial-literacy__description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.5;
  margin: 0;
  color: #fff;
}

.financial-literacy__resources-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
}

.financial-literacy__list {
  padding-left: 0;
  font-size: 16px;
}

.financial-literacy__list-item {
  margin-bottom: 8px;
  color: #fff;
}
li.financial-literacy__list-item{
  position: relative;
  padding-left: 20px;
}
li.financial-literacy__list-item::before{
  content: "";
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
.financial-literacy__button-container {
  text-align: center;
  margin-top: 90px;
}

.financial-literacy__button {
  background-color: white;
  color: #157727;
  padding: 14px 65px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.financial-literacy__button:hover {
  background-color: #f1f1f1;
}
.bottom_inside_divider{
  background-image: url("../src//assets//images//financialeducation/financialeducation__heroSection_center_bg.svg");
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  background-size: 100% 203px;
  bottom: 0;
  height: 203px;
  z-index: 1;
  transform: scale(-1, 1);
}

.why-evergreen {
  padding: 55px 0  100px 0;
  background-color: #edf1ee;
  background-image: url("../src//assets//images//financialeducation/finance.png");
  background-position: right 0 bottom 0;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.why-evergreen__icone{
  position: absolute;
  z-index: 0;
  width: 28px;
  height: auto;
  left: 195px;
  top: 60%;
  transform: translateY(-50%) rotateZ(7deg);
}

.why-evergreen__title {
  font-weight: 700;
  font-size: 50px;
  color: #19352d !important;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 40px;
}

.why-evergreen__description {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 20px;
}



.why-evergreen__image {
  width: 100%;
  max-width: 500px;
  height: auto;
}

/* Courses Grid */
.why-evergreen__courses {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  justify-content: space-between;
  align-items: flex-start;
}
.evergreen__course_icons_holder{
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4c9b54;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 35px;
  font-size: 35px;
}
.why-evergreen__course {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  text-align: center;
}

.why-evergreen__icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.why-evergreen__course-text {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  line-height: 1.2;
}

/* Button */
.why-evergreen__button-container {
  text-align: center;
  margin-top: 50px;
}

.why-evergreen__button {
  background-color: #157727;
  color: white;
  padding: 11px 50px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-inline: auto;
}
.why-evergreen__button_icone{
  font-size: 25px;
  position: absolute;
  left: 70%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.why-evergreen__button:hover .why-evergreen__button_icone{
  left: 84%;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}
.why-evergreen__button:hover {
  background-color: #004d00;
}

/* review section */
.reviews{
  padding: 110px 0 85px 0;
  background-color: #dddddd;
}
.reviews__subtitle{
  text-align: center;
  margin-bottom: 10px;
  color: #157727;
  font-size: 20px;
  font-weight: 600;
}
.reviews__title{
  font-weight: 700;
  font-size: 51px;
  color: #03041c;
  line-height: 1.3;
  text-align: center;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
}
.reviews__title__line{
  position: absolute;
  left: 56%;
  top: 90%;
  transform: translate(-50%,-50%);
  z-index: -1;
}
.reviews__description{
  text-align: center;
  color: #525258;
  font-weight: 500;
  line-height: 1.8;
  padding: 0 35px;
  margin-bottom: 60px;
}
.reviews__cards__wrapper{
  display: flex;
  column-gap: 38px;

}
.reviews__card{
  padding: 55px 32px 70px 32px;
  background-color: #fff;
  border-radius: 35px;
  width: 31%;
}
.card__rating{
  font-weight: 900;
  color: #ffb422;
  font-size: 16px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  margin-bottom: 25px;
}
.card__name{
  font-weight: 600;
  font-size: 21px;
  color: #04121f;
  line-height: 2;
  margin-bottom: 5px;
}
.card__location{
  color: #333;
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.card__text{
  font-weight: 500;
  font-size: 18px;
  color: #666;
  line-height: 2;
  margin-bottom: 5px;
}
.why-evergreen__button--reviews{
  border: 2px solid #fff;
}
.why-evergreen__button--reviews:hover .why-evergreen__button_icone{
  left: 80%;
}
.why-evergreen__button--financial-literacy:hover .why-evergreen__button_icone{
  left: 87%;
}
/* faqs section  */
.finantial_faq {
  padding: 120px 0;
}

.finantial_faq__row {
  display: flex;
  flex-wrap: wrap;
}

.finantial_faq__heading {
  padding-right: 20px;
}

.finantial_faq__tag {
  font-weight: 600;
  font-size: 19px;
  color: #157727;
  text-align: left;
}

.finantial_faq__title {
  font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 51px;
  color: #03041c;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 40px;
  position: relative;
  z-index: 0;
}
.reviews__title__line{
  top: 94%;
  left: 65%;
}
.finantial_faq__highlight {
  position: relative;
  display: inline-block;
}

.finantial_faq__highlight::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 4px;
  background: orange;
}

.finantial_faq__button {
  background: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.finantial_faq__content {
  padding-left: 20px;
}

.finantial_faq__item {
  border: 1px solid #ddd;
  border-radius: 50px;
  margin-bottom: 10px;
  padding: 35px 35px;
}

.finantial_faq__question {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #04121f;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;

}
.finantial_faq__question.active,
.finantial_faq__question:hover{
  color: var(--green);
}
.finantial_faq__question-holder{
  width: calc(100% - 30px);
}
.finantial_faq__icon {
  font-size: 14px;
}

.finantial_faq__answer {
  margin: 25px 0 0 0;
  font-size: 17px;
  color: #525258;
  line-height: 1.6;
}
.faq__icons{
  font-size: 25px;
}
.why-evergreen__button--financial-literacy{
  background-color: #fff;
  color: #157727;
}
.why-evergreen__button--financial-literacy:hover{
  background-color: #fff;  color: #157727;
}
.newsletter{
  padding: 90px  0;
  background-position: center top 29%;
  background-image: linear-gradient(180deg, rgba(44, 174, 118, .75) 0%, rgba(21, 119, 39, .85) 100%), url(https://plus.unsplash.com/premium_photo-1683141035520-9b1e20a92382?q=80&amp;w=2071&amp;auto=format&amp;fit=crop&amp;ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) !important;
}
.newsletter__row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsletter__heading{
  font-weight: 700;
  font-size: 40px;
  color: #FFFFFF ;
  line-height: 1.4;
  text-align: left;
  max-width: 510px;
  margin: 0;
}
.why-evergreen__button--newletter{
  color: #222222 !important;
  border-color: rgba(255, 255, 255, 0);
  background-color: #ffb800;
  margin-inline: 0;
}
.why-evergreen__button--newletter:hover{
  background-color: #ffb800;
}
.why-evergreen__button--newletter:hover .why-evergreen__button_icone{
  left: 86%;
}
.why-evergreen__button--faq{
  margin-inline: 0;
}
.googleAddPopup{
  position: fixed;
  width: 90%;
  max-width: 700px;
  z-index: 999;
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 30px;
  padding: 50px 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.googleAddPopup.hidden{
  display: none;
}
.googleAddPopup.flex{
  display: flex;
}
.googleAddPopup__heading{
  font-size: 25px;
  padding: 0 5%;
  text-align: center;
  color: var(--green);
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 50px;
}
.googleAddPopup__icone{
  font-size: 80px;
  margin-bottom: 30px;
  margin-inline: auto;
  text-align: center;
  color: var(--green);
}
.closrPopup__btn{
  position: absolute;
  top: 15px;
  font-size: 35px;
  color: #000;
  right: 15px;
  cursor: pointer;
}
.errormessage{
  color: red;
  font-size: 14px;
  text-align: left;
}
/* crp faqs */
.space_for_website_top_carpHero{
  padding-top: 105px;
}
.carpHero{
  position: relative;
  min-height: 500px;
}
.carpHero__design{
  position: absolute;
  width: 100%;
  height: 400px;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #4e9c4b,#74b171);
  border-radius: 0 0 250% 250%;
  border-radius: 0 0 100% 100%;
  border-radius: 0 0 50% 50%;
}
.carpHero{
  text-align: center;
}
.carpHero__medal{
  width: 200px;
  height: auto;
  margin: 0 auto 30px auto ;
  text-align: center;
}
.carpHero__heading{
  text-align: center;
  color: var(--green);
  font-weight: bold;
  font-size: 50px;
}
.carpHero__sub-heading{
  margin: 0 auto 30px auto;
  text-align: center;
  color: var(--green);
  font-weight: bold;
  font-size: 34px;
}
.carpHero__desc{
  margin: 0 auto 40px auto;
  text-align: center;
  color: #7e7e7e;
  font-weight: 500;
  font-size: 22px;
  max-width: 800px;
}
.carpHero__btn{
  padding: 12px 50px;
  background-color: var(--green);
  color: #fff !important;
  border: 1px solid var(--green);
  width: fit-content;
  margin: 0 auto 0px auto;
  font-size: 22px;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
}
.carpHero__btn:hover{
  background-color: transparent;
  color: var(--green) !important;
}
.carpHeroCard-holder{
  width: 50%;
}
.count{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid var(--green);
  color: var(--green);
  font-size: 30px;
  font-weight: bold;
  margin: 0 auto 30px auto;
}
.carpHeroCard{
  border-radius: 20px;
  text-align: left;
  position: relative;
  z-index: 1;
}
.carpHeroCard__inner{
  position: relative;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
  padding: 50px 40px;
  border-radius: 20px;
  text-align: left;
  position: relative;
  z-index: 1;
}
.carpHeroCard::before{
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--green);
  top: 12px;
  left: -12px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.carpHeroCard__heading{
  color: var(--green);
  font-weight: bold;
  font-size: 29px;
  margin-bottom: 20px;
}
.carpHeroCard__desc{
  font-size: 20px;
  color: #7e7e7e;
  margin-bottom: 40px;
  min-height: 120px;
}
.carpHeroCard__btn{
  padding: 11px 40px;
  background-color: var(--green);
  color: #fff !important;
  border: 1px solid var(--green);
  width: fit-content;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
}
.carpHeroCard__btn:hover{
  background-color: transparent;
  color: var(--green) !important;
}
.cardRow{
  display: flex;
  column-gap: 70px;
  margin: 150px 0 170px 0;
}
.instrBox__holder{
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
}
.instrBox{
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
  padding: 50px 40px;
  text-align: center;
  color: #7e7e7e;
  font-size: 22px;
  position: relative;
  z-index: 1;
}
.instrBox__holder::before{
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  top: -10px;
  right: -10px;
  background-color: var(--green);
  z-index: -1;
}
.instrBox__holder::after{
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  bottom: -10px;
  left: -10px;
  background-color: var(--green);
  z-index: -1;
}
.finantial_faq__title--crpfaqs,
.finantial_faq__tag--crpfaqs,
.finantial_faq__heading--crpfaqs{
  text-align: center;
  text-transform: none;
}
.inantial_faq__title--crpfaqs{
  margin-bottom: 30px;
}
.finantial_faq__itemList li.finantial_faq__answer{
  padding-left: 15px;
  position: relative;
}
.finantial_faq__itemList li.finantial_faq__answer::before{
  content: "";
  width: 5px;
  height: 5px;
  background-color: #04121f;
  border-radius: 50%;
  top: 10px;
  left: 0;
  position: absolute;
}
.finantial_faq__answer a{
  color: #2196F3 !important;
  cursor: pointer;
}
/* recources */
.financialRec {
  width: 100%;
  padding: 200px 0 100px 0;
  background: linear-gradient(to right, #4e9c4bf6 40%, #4e9c4b2d 100%), url("../src/assets/images/financialRec.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.financialRec__logo{
  width: 200px;
  height: auto;
  margin-bottom: 40px;
}


.financial__heading {
  color: var(--yellow);
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
}

.financial__content {
  text-align: left;
  line-height: 1.6;
  color: #fff;
}

.financial__text {
  font-size: 20px;
  font-weight: 500;
}
.financial__text--mb1{
  margin-bottom: 60px;
}

.financial__text--bold {
  font-weight: bold;
  font-size: 18px;
}

.financial__highlight {
  color: var(--yellow);
  font-weight: bold;
}
.financial__text2{
  color: #fff;
  font-size: 34px;
  margin-bottom: 20px;
  font-weight: bold;
}
.gAddBox__row{
  column-gap: 4px;
}
.gAddBox{
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  border-radius: 50%;
  background-color: var(--green);
}
@media(max-width:1199px){
  .container__fnedu2,
  .container__fnedu {
    width: 100% !important;
    max-width: 1050px !important;
  }
  .container__fnedu2--crpfaqs{
    max-width: 960px !important;
  }
  .why-evergreen__icone{
    left: 55%;
    top: 48%;
  }
  .reviews__title__line {
    left: 32%;
  }
  .signup-form{
    margin: 0;
  }
  .carpHeroCard__heading{
    font-size: 26px;
  }
  .carpHeroCard__desc{
    font-size: 19px;
  }
  .cardRow{
    column-gap: 30px;
    margin: 120px 0 145px 0;
  }
  .carpHeroCard__btn{
    font-size: 18px;
    padding: 11px 35px;
  }
  .instrBox{
    font-size: 20px;
  }
  .carpHeroCard__inner{
    padding: 40px 30px;
  }
  .financialRec{
    padding: 150px 0 60px 0;
  }
  .financialRec__logo {
    width: 170px;
    margin-bottom: 25px;
  }
  .financial__heading{
    font-size: 40px;
  }
  .financial__text--mb1{
    margin-bottom: 35px;
  }
  .financial__text{
    font-size: 18px;
  }
  .financial__text2 {
    font-size: 28px;
    margin-bottom: 12px;
  }
}
@media(max-width:1023px){

  .crbldrSec1__heading{
    font-size: 34px;
    margin-bottom: 10px;
  }
  .crbldrSec1__para {
    font-size: 19px;
    margin-bottom: 30px;
    max-width: 600px;
  }
  .crbldrSec1__heading2{
    font-size: 19px;
  }
  .crbldrSec1__listBox__heading {
    font-size: 18px;
  }
  .crbldrSec1__listBoxList {
    padding: 25px 20px 80px 22px;
    font-size: 15px;
  }
  .crbldrSec1__col1__desc {
    font-size: 16px;
  }
  .couterNumberBox__para {
    width: calc(100% - 50px);
    font-size: 19px;
  }
  .questionCallBox__heading {
    font-size: 17px;
  }
  .questionCallBox__btn{
    font-size: 18px;
  }
  .credit-comparison{
    column-gap: 10px;
  }
  .credit-comparison__title {
    font-size: 21px;
    max-width: 200px;
  }
  .container__small{
    max-width: 760px !important;
  }
  .credit-comparison__text{
    padding: 17px 15px;
  }
  .credit-comparison__text{
    font-size: 11px;
  }
  .credit-comparison__cross-icon {
    width: 16.5px;
    height: 16.6px;
  }
  .secDevider__heading{
    font-size: 22px;
  }
  .secDevider {
    padding: 20px 0;
  }
  .comment-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .comment-text{
    font-size: 14px;
  }
  .comment-card{
    padding: 15px;
  }
  .about7steps__text {
    max-width: 630px;
    font-size: 17px;
  }
  .features {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 15px;
  }
  .feature__title {
    font-size: 18px;
  }
  .signup-form{
    max-width: 400px;
    margin: 30px auto 0 auto;
  }
  .fnedu_heroSection__contentHolder{
    max-width: 600px;
    margin: 0px auto 0 auto;
  }
  .top_row {
    margin-bottom: 75px;
  }
  .financial-literacy__title {
    margin-bottom: 27px;
  }
  .financial-literacy__description{
    margin-bottom: 30px;
  }
  .bottomRow_fna_hersec{
    padding: 0 50px;
  }
  .why-evergreen__contentHolder{
    margin-bottom: 30px;
  }
  .why-evergreen__courses{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .why-evergreen__image{
    max-width: 100%;
    margin-bottom: 30px;
  }
  .why-evergreen__course{
    width: 48%;
  }
  .reviews__title__line--review {
    left: 48%;
    top: 49%;
  }
  .reviews__cards__wrapper{
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  .reviews__card{
    width: 100%;
    max-width: 380px;
  }
  .finantial_faq__content{
    padding-left: 0;
  }
  .finantial_faq__heading{
    margin-bottom: 50px;
  }
  .finantial_faq__title{
    margin-bottom: 20px;
  }
  .reviews__title__line--faq {
    left: 44%;
    top: 94%;
  }
  .newsletter__heading {
    font-size: 35px;
    max-width: 340px;
}
}
@media(max-width:990px){
  .container__fnedu2,
  .container__fnedu {
    width: 100% !important;
    max-width: 700px !important;
  }
  .cardRow{
    flex-wrap: wrap;
    row-gap: 70px;
  }
  .carpHeroCard-holder{
    width: 100%;
  }
  .financialRec{
    background: linear-gradient(to right, #4e9c4be5 40%, #4e9c4b67 100%), url("../src/assets/images/financialRec2.jpg");
    background-position: center;
  }
  .reviews__title__line--review{
    display: none;
  }
}
@media(max-width:600px){
  .container__small{
    max-width: 350px !important;
  }
  .container__fnedu2,
  .container__fnedu {
    width: 100% !important;
    max-width: 400px !important;
    padding: 0 20px !important;
  }
  .space_for_website_top {
    padding-top: 120px;
  }
  .crbldrSec1__heading {
    font-size: 25px;
    margin-bottom: 30px;
    max-width: 230px;
    margin-inline: auto;
    line-height: 1.2;
  }
  .crbldrSec1__para{
    font-size: 16px;
    line-height: 1.2;
  }
  .crbldrSec1__heading2 {
    font-size: 14px;
  }
  .crbldrSec1__wrapper{
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .crbldrSec1__col1,.crbldrSec1__col2{
    width: 100%;
  }
  .couterNumberBoxHolder{
    transform: translate(0);
  }
  .couterNumber__wrapper{
    flex-wrap: wrap;
  }
  .couterNumberBox{
    width: 100%;
  }
  .couterNumberBox__para {
    font-size: 16px;
  }
  .questionCallBox{
    margin-top: 0;
  }
  .credit-comparison{
    flex-wrap: wrap;
    transform: translate(0);
    row-gap: 60px;
  }
  .crbldrSec1{
    margin-bottom: 35px;
  }
  .credit-comparison__column{
    flex: 1 1 100%;
    padding-bottom: 75px;
  }
  .credit-comparison__text {
    font-size: 13px;
  }
  .credit-comparison__text br{
    display: none;
  }
  .credit-comparison__button{
    width: 85%;
    font-size: 14px;
    padding-inline: 25px;
  }
  .credit-comparison__title {
    font-size: 19px;
  }
  .credit-comparison-sec{
    padding-bottom: 60px;
  }
  .secDevider__heading {
    font-size: 19px;
  }
  .features {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px 15px;
  }
  .comment-card{
    width: 100%;
  }
  .faq {
      padding: 11px;
  }
  .faq__question {
    padding: 0px 10px;
    font-size: 15px;
  }
  .faq__answer{
    font-size: 14px;
  }
  .faq__item--active .faq__answer {
    padding: 0px 10px;
  }
  .cta__button{
    font-size: 16px;
  }
  .cta--about7steps{
    font-size: 13px;
  }
  .fnedu_heroSection__top{
    padding-top: 60px;
  }
  .fnedu_heroSection__logo {
    width: 130px;
    margin-bottom: 25px;
  }
  .fnedu_heroSection__heading{
    font-size: 32px;
  }
  .fnedu_heroSection__subheading {
    font-size: 18px;
    margin-bottom: 28px;
  }
  .fnedu_heroSectionLisy__text{
    font-size: 16px;
  }
  .signup-form {
    padding: 28px 26px;
  }
  .signup-form__description {
    margin-bottom: 45px;
    font-size: 15px;
  }
  .bottomRow_fna_hersec{
    padding: 0 10px;
  }
  .why-evergreen__title {
    font-size: 27px;
    margin-bottom: 15px;
  }
  .why-evergreen__description {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .evergreen__course_icons_holder {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    font-size: 30px;
  }
  .why-evergreen__course-text {
    font-size: 16px;
  }
  .why-evergreen__courses {
    gap: 12px;
    margin-top: 10px;
  }
  .top_row {
    margin-bottom: 49px;
  }
  .financial-literacy__title {
    font-size: 27px;
  }
  .financial-literacy__description{
    font-size: 15px;
  }
  .financial-literacy__resources-title {
    font-size: 16px;
  }
  li.financial-literacy__list-item{
    font-size: 15px;
  }
  .why-evergreen__button {
    padding: 10px 40px;
    font-size: 18px;
  }
  .fnedu_heroSection {
    padding: 0 0 100px 0;
  }
  .fnedu_heroSection::after{
    height: 60px;
  }
  .why-evergreen__button-container {
    margin-top: 38px;
  }
  .why-evergreen {
    padding: 35px 0 60px 0;
  }
  .reviews {
    padding: 55px 0 55px 0;
  }
  .reviews__subtitle {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .reviews__title {
    font-size: 30px;
    margin-bottom: 18px;
  }
  .reviews__title__line{
    width: 170px;
  }
  .reviews__description{
    padding: 0;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .reviews__card {
    padding: 35px 25px 40px 25px;
  }
  .card__rating {
    margin-bottom: 12px;
  }
  .card__name{
    font-size: 18px;
  }
  .card__location{
    font-size: 14px;
  }
  .card__text{
    font-size: 15px;
    margin-bottom: 0;
  }
  .finantial_faq{
    padding: 50px 0;
  }
  .finantial_faq__tag{
    font-size: 16px;
    margin-bottom: 5px;
  }
  .finantial_faq__title{
    font-size: 35px;
    margin-bottom: 20px;
  }
  .finantial_faq__item{
    padding: 20px;
  }
  .finantial_faq__question{
    font-size: 15px;
  }
  .finantial_faq__question-holder {
    width: calc(100% - 22px);
  }
  .faq__icons {
    font-size: 20px;
  }
  .finantial_faq__answer {
    margin: 15px 0 0 0;
    font-size: 14px;
  }
  .newsletter{
    padding: 45px 0;
  }
  .newsletter__row{
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
  }
  .newsletter__heading {
    font-size: 24px;
  }
  .signup-form__input,
  .signup-form__button{
    font-size: 14px;
    padding: 0.6rem 0.8rem;
  }
  .signup-form__label{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .signup-form__checkbox-label {
    font-size: 13px;
  }
  .googleAddPopup__heading{
    font-size: 18px;
    padding: 0;
    margin-bottom: 20px;
  }
  .googleAddPopup{
    padding: 40px 24px;
  }
  .googleAddPopup__icone {
    font-size: 58px;
    margin-bottom: 20px;
  }
} 

.collect-info-modal-container .modal-content {
  width: 80%;
  margin: 110px auto auto;
}
  .carpHero__medal {
    width: 110px;
    margin: 0 auto 18px auto;
  }
  .carpHero__heading{
    font-size: 32px;
  }
  .carpHero__sub-heading{
    font-size: 20px;
    margin-bottom: 15px;
  }
  .carpHero__desc{
    font-size: 16px;
  }
  .carpHero__btn {
    padding: 10px 33px;
    font-size: 18px;
  }
  .count {
    width: 60px;
    height: 60px;
    font-size: 24px;
    margin: 0 auto 20px auto;
  }
  .carpHeroCard::before{
    top: 8px;
    left: -8px;
  }
  .carpHeroCard__inner {
    padding: 30px 20px;
  }
  .carpHeroCard__heading{
    font-size: 22px;
    margin-bottom: 10px;
  }
  .carpHeroCard__desc{
    font-size: 17px;
    margin-bottom: 20px;
  }
  .carpHeroCard__btn {
    font-size: 14px;
    padding: 10px 15px;
  }
  .cardRow {
    row-gap: 40px;
    margin: 70px 0 70px 0;
  }
  .instrBox {
    border-radius: 20px;
    padding: 25px 20px;
    font-size: 16px;
  }
  .instrBox__holder::before {
    top: -7px;
    right: -7px;
  }
  .instrBox__holder::after {
    bottom: -7px;
    left: -7px;
  }
  .finantial_faq__heading--crpfaqs{
    padding-right: 0;
    margin-bottom: 20px;
  
  }
  .finantial_faq__title--crpfaqs{
    font-size: 28px;
  }


  .tooltip-inner {
    background-color: #ffffff !important; /* Pure white */
    color: black !important;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25); /* Stronger shadow */
    border: 1px solid #bbb; /* Slightly darker border for visibility */
    position: relative;
}
.tooltip {
  position: absolute !important;
  z-index: 9999 !important; /* Ensure it stays on top */
  opacity: 1 !important; /* Ensure full visibility */
}
  
  .tooltip.bs-tooltip-top .arrow::before {
    border-top-color: white !important;
  }
  
  .tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: white !important;
  }
  
  .tooltip.bs-tooltip-start .arrow::before {
    border-left-color: white !important;
  }
  
  .tooltip.bs-tooltip-end .arrow::before {
    border-right-color: white !important;
  }
  